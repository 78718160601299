import { UmbracoTypes } from '~/lib/data-contract';
import { useFrame, usePage } from '~/services';
import DynamicBlocks from '~/templates/blocks';
import { Container } from './styles';
import { Schema, SchemaTypes } from '~/shared/schema';

type FrontPages = UmbracoTypes.IP20FrontPage | UmbracoTypes.IP21FrontPageLouisPoulsen;

export default function P20FrontPage() {
    const { data: page } = usePage<FrontPages>();
    const { data: frame } = useFrame();

    const name = 'Johannes Fog A/S';

    return (
        <Container>
            <DynamicBlocks elements={page?.pageElements as UmbracoTypes.IModules[]} />

            <Schema
                type={SchemaTypes.Website}
                props={{
                    searchPath: frame?.staticLinks?.searchPage?.url,
                    name,
                }}
            />
        </Container>
    );
}
